
























































import Vue from 'vue'

import {
  BOverlay,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { appFamilyDetails } from '@/store/modules/app-families/app-family-details'
import UserParentsList from '@/views/components/parents-list/UserParentsList.vue'
import ParentsChildrenList from '@/views/components/parents-children-list/ParentsChildrenList.vue'
import TransactionList from '@/views/admin/transactions-v2/transactions-list/TransactionsList.vue'
import { permissionSubjects } from '@/libs/acl/constants'
import ApplicationsList from '@/views/admin/parents/parent-applications/ApplicationsList.vue'
import ApplicationTaskList from '@/views/admin/tasks/application-task-list/ApplicationTaskList.vue'
import FamilyAttributesList from '@/views/components/family-attributes-list/FamilyAttributesList.vue'

export default Vue.extend({
  name: 'FamilyDetails',
  components: {
    ApplicationTaskList,
    ApplicationsList,
    TransactionList,
    BOverlay,
    ParentsChildrenList,
    UserParentsList,
    FamilyAttributesList,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      tabIndex: 0,
      permissionSubjects,
    }
  },
  computed: {
    isLoading() {
      return appFamilyDetails.getters.getIsLoading
    },
    familyData() {
      return appFamilyDetails.getters.getFamily
    },
    familyId() {
      return appFamilyDetails.getters.getFamilyId
    },
    familyAttributesColumns() {
      return appFamilyDetails.getters.getFamilyAttributesColumns
    },
  },
  async created() {
    const currentFamilyId = this.$router.currentRoute.params.id
    appFamilyDetails.mutations.SET_FAMILY_ID(currentFamilyId)

    await this.prepareFamilyData()
  },
  beforeDestroy() {
    appFamilyDetails.mutations.INIT_STATE()
  },
  methods: {
    async prepareFamilyData() {
      appFamilyDetails.mutations.SET_IS_LOADING(true)

      await appFamilyDetails.actions.fetchFamilyData()

      appFamilyDetails.mutations.SET_IS_LOADING(false)
    },
  },
})

import Vue from 'vue'
// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { createModule } from 'vuexok'
import router from '@/router'
import store from '@/store'
import { ChildrenListState, IChildrenListRequest } from './types'
import { fetchUsersList } from '@/api/users'
import { DEFAULT_PER_PAGE, PER_PAGE_OPTIONS } from '@/constants/base'
import setPerPage from '@/helpers/setPerPage'
import { updateMember } from '@/api/familyMember'

// eslint-disable-next-line import/prefer-default-export
export const appChildrenList = createModule(
  'appChildrenList',
  {
    namespaced: true,
    state: {
      tableColumns: [
        { key: 'name', label: 'name', sortable: false },
        { key: 'email', label: 'email', sortable: false },
        { key: 'phone', label: 'phone', sortable: false },
        { key: 'actions' },
      ],
      perPage: Number(localStorage.getItem('childrenPerPage')) || DEFAULT_PER_PAGE,
      totalItems: 0,
      currentPage: 1,
      perPageOptions: PER_PAGE_OPTIONS,
      searchQuery: '' as string | (string | null)[],
      sortBy: 'created_at',
      isSortDirDesc: true,
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
    } as ChildrenListState,
    getters: {
      getTableColumns: state => state.tableColumns,
      getPerPage: state => state.perPage,
      getTotalItems: state => state.totalItems,
      getCurrentPage: state => state.currentPage,
      getPerPageOptions: state => state.perPageOptions,
      getSearchQuery: state => state.searchQuery,
      getSortBy: state => state.sortBy,
      getIsSortDirDesc: state => state.isSortDirDesc,
      getDataMeta: state => state.dataMeta,
    },
    mutations: {
      SET_PER_PAGE(state, val: number) {
        localStorage.setItem('childrenPerPage', val.toString())
        state.perPage = val
      },
      SET_TOTAL_ITEMS(state, val: number) {
        state.totalItems = val
      },
      SET_CURRENT_PAGE(state, val: number) {
        state.currentPage = val
      },
      SET_SEARCH_QUERY(state, val: string) {
        state.searchQuery = val
      },
      SET_SORT_BY(state, val: string) {
        state.sortBy = val
      },
      SET_IS_SORT_DESC(state, val: boolean) {
        state.isSortDirDesc = val
      },
      SET_DATA_META(state, val) {
        state.dataMeta = val
      },
      SET_FILTER_PARAMS(state) {
        const query = { ...router.currentRoute.query }
        const defaultPerPage = localStorage.getItem('childrenPerPage')
        state.currentPage = Number(query.currentPage) || 1
        state.perPage = setPerPage(query.perPage, defaultPerPage, DEFAULT_PER_PAGE)
        state.searchQuery = query.searchQuery || ''
      },
    },
    actions: {
      async fetchChildrenList({ state }, familyId) {
        try {
          const queryParams: IChildrenListRequest = {
            perPage: state.perPage,
            page: state.currentPage || 1,
            sortBy: state.sortBy,
            sortDesc: state.isSortDirDesc,
            IsParentMemberRole: false,
          }
          if (familyId) queryParams.familyId = familyId

          const response: any = await fetchUsersList(queryParams)

          const {
            total, from, to,
          } = response.data.meta

          const dataMeta = {
            from,
            to,
            of: total,
          }

          appChildrenList.mutations.SET_TOTAL_ITEMS(total)
          appChildrenList.mutations.SET_DATA_META(dataMeta)
          return response.data.data
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching children',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return []
        }
      },
      async updFamilyMember({ state }, data) {
        try {
          await updateMember(data)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'User was successfully updated',
              icon: 'ThumbsUpIcon',
              variant: 'info',
            },
          })
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating User',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      },
    },
  },
)

appChildrenList.register(store)
